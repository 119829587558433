import firebase from 'firebase/app'
import store from '@/store'

export default class UpdateByUserID {
  constructor() {
    this.label = ''
  }

  async update(payload) {
    const {
      collection, data,
    } = payload

    try {
      const { uid } = store.state.userBasic
      data.uid = uid
      data.updateTime = new Date()
      await firebase.firestore().collection(collection).doc(uid).set(data, { merge: true })

      const response = {
        status: 'success',
      }
      return response
    } catch (error) {
      const response = {
        status: 'error',
        msg: error,
      }
      return response
    }
  }
}
