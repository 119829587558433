<template>
  <div class="">
    <h2>プロフィール</h2>
    <b-alert
      variant="success"
      show
    >
      <div class="alert-body mt-1">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar
              size="35"
              src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-green.png?alt=media&token=decd9d77-1d03-4797-b794-a03bf691d4e5"
              variant="light-primary"
            />
          </div>
          <div class="">
            事前にプロフィールをご入力頂くと、より早くお仕事をご紹介できます！
          </div>
        </div>
      </div>
    </b-alert>
    <b-card
      title=""
    >
      <b-form>
        <b-row
          v-for="(item, index) in menulist"
          :key="index"
        >
          <InputParts
            v-if="useValueDicList.indexOf(item.type) !== -1"
            :label="item.label"
            :type="item.type"
            :placeholder-text="typeof item.placeholderText !== 'undefined'? item.placeholderText: ''"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value-dic="inputForm[item.key]"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            @inputData="inputForm[item.key] = $event"
          />
          <InputParts
            v-else
            :label="item.label"
            :type="item.type"
            :description="item.description"
            :placeholder-text="typeof item.placeholderText !== 'undefined'? item.placeholderText: ''"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value="item.type !=='checkbox' ?inputForm[item.key]: ''"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            @inputData="inputForm[item.key] = $event"
          />
        </b-row>
        <div v-if="errorList.length > 0">
          <b-alert
            variant="danger"
            show
            class="alertSpace"
          >
            <div class="alert-body">
              <div class="d-flex mb-2">
                <div class="mr-1">
                  <b-avatar
                    size="30"
                    src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgril-red.png?alt=media&token=4033ef9a-cd19-48e6-baf6-85065c0a3c96"
                    variant="light-primary"
                  />
                </div>
                <div class="">
                  {{ errorMSG }}
                </div>
              </div>
              <div
                v-for="(item, index) in errorList"
                :key="index"
                class=""
              >
                ・<b>{{ item }}</b>
              </div>
            </div>
          </b-alert>
        </div>
        <b-row class="mt-2">
          <!-- submit and reset -->
          <b-col class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="confirm"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              登録する
              <b-spinner
                v-if="status === 2"
                small
              />
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="ml-1"
              size="sm"
              @click="move('hearing')"
            >
              続けてアンケート
              <feather-icon
                icon="ChevronsRightIcon"
                class="mr-50"
              />
            </b-button> -->
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar
              size="35"
              src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-bule.png?alt=media&token=a579655b-d85a-437a-93ce-034100fcb34e"
              variant="light-primary"
            />
          </div>
          <div class="">
            続けて
            <b>アンケート</b>
            もご入力ください。より希望に沿ったお仕事をご紹介できますよ！
          </div>
        </div>
      </div>
    </b-alert>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BForm, BButton, BCard, BSpinner, BAlert, BAvatar,
} from 'bootstrap-vue'
import InputParts from '@/components/molecules/input/InputParts.vue'
/* eslint-disable */
import menulist from '@/components/conf/プロフィール.json'
/* eslint-enable */
import getSingleData from '@/firestore/data/get'
import UpdateByUserID from '@/firestore/data/UpdateByUserID'
import CreateOrUpdateData from '@/firestore/user/CreateOrUpdateData'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    InputParts,
    BSpinner,
    BAlert,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputForm: {
        mail: '',
      },
      status: 1,
      isShow: false,
      menulist,
      selected: [],
      errorList: [],
      useValueDicList: ['checkbox', 'selectSingle', 'languageLevelSelect', 'addressInput', 'radio'],
    }
  },
  computed: {
  },
  async mounted() {
    const data = {
      collection: 'profile',
    }
    const response = await getSingleData(data)
    if (response.status === 'success') {
      this.inputForm = response.data
      if (store.state.userBasic.mail !== '') {
        this.inputForm.mail = store.state.userBasic.mail
        this.menulist.find(v => v.key === 'mail').disabled = true
      }
    } else {
      window.console.log('error', response.msg)
    }
  },
  methods: {
    isInvalid() {
      this.errorList = []
      const datakeys = Object.keys(this.inputForm)
      this.menulist.forEach(item => {
        // 全く操作していない場合：undefinedになる
        if (item.required && !datakeys.includes(item.key)) {
          this.errorList.push(item.label)
        }
        // フォームを選択したが空白の場合
        if (item.required && datakeys.includes(item.key) && this.inputForm[item.key] === '') {
          this.errorList.push(item.label)
        }
        if (item.required && typeof this.inputForm[item.key] === 'object') {
          if (Object.keys(this.inputForm[item.key]).length === 0) this.errorList.push(item.label)
        }
      })
      if (this.errorList.length > 0) {
        this.errorMSG = '下記の項目を入力してください。'
        return true
      }
      return false
    },
    async confirm() {
      this.status = 2
      const isInvalid = this.isInvalid()
      if (isInvalid) {
        this.status = 1
        return
      }
      // Twitter経由の場合、mailが取得できなかったためここでSTOREに格納
      if (store.state.userBasic.mail === '') this.$store.dispatch('userBasic/updateMail', this.inputForm.mail)
      this.inputForm.displayStatus = 'active'
      const d = {
        collection: 'profile',
        data: this.inputForm,
      }
      const updateData = new CreateOrUpdateData()
      const response = await updateData.play(d)
      if (response.status === 'success') {
        response.type === 'create' ? this.updateFlag() : this.success()
      } else {
        window.console.log('error', response.msg)
      }
    },
    async updateFlag() {
      const d = {
        collection: 'user',
        data: {
          isUserProfile: true,
          status: {
            labelName: 'プロフ作成済み',
            status: 'profile',
          },
        },
      }
      const updateByUserID = new UpdateByUserID()
      const response = await updateByUserID.update(d)
      if (response.status === 'success') {
        this.success()
      } else {
        window.console.log('error', response.msg)
      }
    },
    showBtn() {
      this.$ref.sbchild.$emit('success')
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'データが正常に登録されました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.status = 1
        if (this.$router.currentRoute.name === 'dashboad') {
          this.$emit('childevent')
          this.$emit('openHearingEditor')
        } else {
          this.$router.push({ name: 'profile' })
        }
      })
    },
    move(val) {
      this.$router.push({ name: val })
    },
  },
}
</script>

<style scoped>
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .space_ {
    width: 50%;
    margin: 0 auto;
  }
}
</style>
