import firebase from 'firebase/app'
import store from '@/store'

export default class CreateOrUpdateData {
  constructor() {
    this.label = ''
  }

  async play(payload) {
    const { data, collection } = payload
    let dataDic = {}
    const { uid } = store.state.userBasic
    try {
      await firebase.firestore().collection(collection)
        .where('uid', '==', uid)
        .get()
        .then(sn => {
          if (!sn.empty) {
            sn.forEach(doc => {
              dataDic = doc.data() || {}
            })
          }
        })
      if (Object.keys(dataDic).length !== 0) {
        await firebase.firestore().collection(collection).doc(dataDic.docID).set(data, { merge: true })
        return { status: 'success', type: 'update' }
      }

      const promise = await firebase.firestore().collection(collection).doc()
      data.docID = promise.id
      data.uid = uid
      data.mail = store.state.userBasic.mail || ''
      data.insertTime = new Date()
      await firebase.firestore().collection(collection).doc(promise.id).set(data, { merge: true })

      return { status: 'success', type: 'create' }
    } catch (error) {
      const response = {
        status: 'error',
        msg: error,
      }
      window.console.log('error', error)
      return response
    }
  }
}
